import React from 'react';
import { View, Text, Link } from './common';
import Content from './content';
import { colors } from '../style';
import Logo from '../images/logo-light.svg';

function FooterSection({ title, children }) {
  return (
    <View
      style={{ '& li': { margin: 0, marginBottom: 4 } }}
      className="mt-10 md:mt-0 md:ml-20 text-gray-600"
    >
      <Text className="mb-4">
        <strong>{title}</strong>
      </Text>
      {children}
    </View>
  );
}

export default () => (
  <Content className="footer self-start pb-12 md:pb-32 mt-48">
    <View className="flex-col md:flex-row justify-between items-start">
      <View>
        <div>
          <img src={Logo} style={{ width: 20 }} />
        </div>

        <div className="text-sm mt-8">
          <div className="mb-1">
            <Text style={{ color: colors.n5, marginTop: 20 }}>&copy; 2020 Shift Reset LLC</Text>
          </div>
          <Link to="/terms-and-privacy" color={colors.n5}>
            Terms and Privacy
          </Link>
        </div>
      </View>

      <View className="flex-col md:flex-row">
        <FooterSection title="Links">
          <ul>
            <li>
              <Link to="/#features">Features</Link>
            </li>
            <li>
              <Link external to="/pricing">
                Pricing
              </Link>
            </li>
            <li>
              <Link to="/docs">Docs</Link>
            </li>
            <li>
              <Link to="/account">Account</Link>
            </li>
          </ul>
        </FooterSection>

        <FooterSection title="Community">
          <ul>
            <li>
              <Link external to="https://slack.actualbudget.com" color={colors.n1}>
                Slack
              </Link>
            </li>
            <li>
              <Link external to="https://spectrum.chat/actualbudget" color={colors.n1}>
                Spectrum
              </Link>
            </li>
            <li>
              <Link external to="https://www.reddit.com/r/actualbudget" color={colors.n1}>
                Reddit
              </Link>
            </li>
            <li>
              <Link external to="https://trello.com/b/3S1pscsJ/actual" color={colors.n1}>
                Bugs & Roadmap
              </Link>
            </li>
          </ul>
        </FooterSection>

        <FooterSection title="Contact">
          <ul>
            <li>
              <Link external to="mailto:help@actualbudget.com" color={colors.n1}>
                Email
              </Link>
            </li>
            <li>
              <Link external to="https://twitter.com/actualbudget" color={colors.n1}>
                Twitter
              </Link>
            </li>
          </ul>
        </FooterSection>
      </View>
    </View>
  </Content>
);
