export const colors = {
  y1: '#8D2B0B',
  y2: '#B44D12',
  y3: '#CB6E17',
  y4: '#DE911D',
  y5: '#F0B429',
  y6: '#F7C948',
  y7: '#FADB5F',
  y8: '#FCE588',
  y9: '#FFF3C4',
  y10: '#FFFBEA',
  y11: '#FFFEFA',
  r1: '#610316',
  r2: '#8A041A',
  r3: '#AB091E',
  r4: '#CF1124',
  r5: '#E12D39',
  r6: '#EF4E4E',
  r7: '#F86A6A',
  r8: '#FF9B9B',
  r9: '#FFBDBD',
  r10: '#FFE3E3',
  r11: '#FFF7F7',
  b1: '#035388',
  b2: '#0B69A3',
  b3: '#127FBF',
  b4: '#1992D4',
  b5: '#2BB0ED',
  b6: '#40C3F7',
  b7: '#5ED0FA',
  b8: '#81DEFD',
  b9: '#B3ECFF',
  b10: '#E3F8FF',
  b11: '#F5FCFF',
  n1: 'black',
  n2: '#243B53',
  n3: '#334E68',
  n4: '#486581',
  n5: '#627D98',
  n6: '#829AB1',
  n7: '#9FB3C8',
  n8: '#BCCCDC',
  n9: '#D9E2EC',
  n10: '#E8ECF0',
  n11: '#F7FAFC',
  g1: '#014D40',
  g2: '#0C6B58',
  g3: '#147D64',
  g4: '#199473',
  g5: '#27AB83',
  g6: '#3EBD93',
  g7: '#65D6AD',
  g8: '#8EEDC7',
  g9: '#C6F7E2',
  g10: '#EFFCF6',
  g11: '#FAFFFD',
  p1: '#44056E',
  p2: '#580A94',
  p3: '#690CB0',
  p4: '#7A0ECC',
  p5: '#8719E0',
  p6: '#9446ED',
  p7: '#A368FC',
  p8: '#B990FF',
  p9: '#DAC4FF',
  p10: '#F2EBFE',
  p11: '#F9F6FE'
};

export const gradients = {
  bluePurple: 'linear-gradient(170deg, #486581, #665D9E)'
};
