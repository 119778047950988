import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { View } from './common';
import { colors } from '../style';

const isDev = process.env.GATSBY_APP_ENVIRON === 'dev';

import Header from './Header';
import Footer from './Footer';
import './reset.css';
import './layout.css';

// See https://github.com/WICG/focus-visible. Only makes the blue
// focus outline appear from keyboard events.
require('focus-visible');

const Layout = ({ style, showHeader = true, showFooter = true, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' }
          ]}
        >
          <html lang="en" />
          <link
            href="https://fonts.googleapis.com/css?family=Libre+Baskerville&display=swap"
            rel="stylesheet"
          />
          // primary
          <meta name="title" content="Actual" />
          <meta name="description" content="Track your money, save money." />
          // facebook
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://actualbudget.com/" />
          <meta property="og:title" content="Actual" />
          <meta
            property="og:description"
            content="Track your money, save money."
          />
          <meta
            property="og:image"
            content="https://actualbudget.com/meta-image.png"
          />
          // twitter
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://actualbudget.com/" />
          <meta property="twitter:title" content="Actual" />
          <meta
            property="twitter:description"
            content="Track your money, save money."
          />
          <meta
            property="twitter:image"
            content="https://actualbudget.com/meta-image.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="/android-icon-192x192.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href="/favicon-96x96.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
        </Helmet>
        <View style={[style, !showHeader && { border: 'none' }]}>
          {isDev && (
            <View
              style={{
                position: 'fixed',
                top: 0,
                right: 0,
                backgroundColor: colors.r8,
                padding: "5px 8px",
                color: colors.r1,
                borderRadius: 4,
                margin: 10
              }}
            >
              development
            </View>
          )}
          {showHeader && <Header siteTitle={data.site.siteMetadata.title} />}
          {children}

          {showFooter && (
            <React.Fragment>
              <View style={{ flex: 1 }} />
              <Footer />
            </React.Fragment>
          )}
        </View>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
